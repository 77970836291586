<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="700px"  
  >
    <v-card> 
      <v-card-title>        
        <v-container>
          <v-row justify="center" align="center" class="titulosCuotas mr-5 ml-3 mt-n8" style="height:45px">
          <v-col md="12" class="gray--text text-center font-weight-bold justify-center align-center">
            DECIDÍ FECHA DE PAGO 
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="12">
            <p class="subTitleTabla ml-3 mt-6 colorCustom2--text font-weight-bold"> {{ mensaje }} </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
        <v-card-title class="mt-n10">
          <v-row align="center">
            <v-date-picker
              color="#5596BB"
              v-model="date"
              full-width
              class="mt-2"
              locale="es-419"
              :min='nowDate'
              no-title
              @change="getMontoTotal()"
            ></v-date-picker>
        </v-row>
      </v-card-title>   
        <v-container style="margin-top:20px;">
          <v-row justify="center" align="center" class="mr-5 ml-3" style="background-color:rgb(29, 11, 255);height:45px">
            <v-col md="3" class="white--text text-center font-weight-bold justify-center align-center">
              <p>IMPORTE</p>
            </v-col>
            <v-col md="1" class="white--text text-center font-weight-bold justify-center align-center">
              <P>+</P>
            </v-col>
            <v-col md="3" class="white--text text-center font-weight-bold justify-center align-center">
            <P>INTERESES</P> 
            </v-col>
            <v-col md="1" class="white--text text-center font-weight-bold justify-center align-center">
              <P>=</P>
            </v-col>
            <v-col md="4" class="white--text text-center font-weight-bold justify-center align-center">
              <P>TOTAL</P>
            </v-col>    
          </v-row>
          <v-row justify="center" align="center" color="" class="mr-5 ml-3" style="background-color:#E0E0E0;height:45px">
            <v-col md="3" class="colorCustom1--text text-center font-weight-bold">
              <p>$ {{ $formatNumber(importe) }} </p>
            </v-col>
            <v-col md="1" class="colorCustom1--text text-center font-weight-bold">
              <P>+</P>
            </v-col>
            <v-col md="3" class="colorCustom1--text text-center font-weight-bold">
            <P>$ {{ $formatNumber(intereses) }}</P> 
            </v-col>
            <v-col md="1" class="colorCustom1--text text-center font-weight-bold">
              <P>=</P>
            </v-col>
            <v-col md="4" class="colorCustom1--text text-center font-weight-bold">
              <P>$ {{ $formatNumber(total) }}</P>
            </v-col>    
          </v-row> 
          <v-row>
            <v-col class="mx-10">
              <p class="text-subtitle-2 red--text text-decoration-underline text--darken-4 ml-2" style="display: inline;">Atención</p>
              <p class="text-subtitle-2 red--text text--darken-4" style="display: inline;">: al optar por una fecha posterior, se recalcularán intereses a la fecha elegida.</p>
            </v-col>
          </v-row>
        </v-container> 
      <v-card-actions class="justify-center">
        <botonVolver @backPage="show = false" :prevent="true" class="mr-2 ml-2" />
        <botonBuscar class="mr-2 ml-2" @Click="Confirmar" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import moment from 'moment'
  export default {
    data () {
      return {
       date : '',
       tributo : '',
       cuenta: '',
       selected: '',
       totalSeleccion: '',
       total: '',
       mensaje: '',
       nowDate:'',
      }
    },
    props: {
      value: Boolean,
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      importe: function () {
        return this.totalSeleccion 
      },
      intereses: function () {
        return this.total - this.totalSeleccion
      }, 
    },
    methods: {
      setForm: function (currentDate, tributo, cuenta, selected,totalSeleccion, mensaje) {
        this.date = currentDate
        this.nowDate = currentDate
        this.tributo = tributo
        this.cuenta = cuenta
        this.selected = selected
        this.totalSeleccion = totalSeleccion
        this.total = totalSeleccion
        this.mensaje = mensaje
        this.getMontoTotal()
      },
      getMontoTotal: async function () {
      var url = ''  
      switch (this.tributo) {
        case 'ALCVP Y SV':        
            url = '/SAT_WS/rest/recibo/' + this.cuenta + '/monto/ABL/totem'
          break;  
        case 'RODADOS':  
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/monto/RODADOS/totem'
          break; 
        case 'VEHICULOS':  
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/monto/VEHICULOS/totem'
          break;    
        case 'COMERCIOS': 
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/monto/COMERCIOS/totem'      
          break;                             
        default:
          break;
      }        
      console.log(url)
       await this.$store.dispatch('axiosMod/postData', { url: url, dataForm: { fecha: moment(this.date).format(), cuotas: this.selected } }).then((response) => { 
        console.log('TOTAL:',response.totalCoutasGeneral)
        this.total = response.totalCoutasGeneral.total
      }).catch(() => {
          this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
      })       
      },
      Confirmar: function () {
        this.$emit('ConfirmDate',this.date)
        this.show=false
      }
    },
  }
</script>
<style>
.v-date-picker-table.v-date-picker-table--date.theme--light {
    height: 100% !important;
}
.subTitleTabla {
  font-size: 16px !important;
}
</style>